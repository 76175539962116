exports.components = {
  "component---src-pages-colecciones-tsx": () => import("./../../../src/pages/colecciones.tsx" /* webpackChunkName: "component---src-pages-colecciones-tsx" */),
  "component---src-pages-gracias-tsx": () => import("./../../../src/pages/gracias.tsx" /* webpackChunkName: "component---src-pages-gracias-tsx" */),
  "component---src-templates-coleccion-tsx": () => import("./../../../src/templates/coleccion.tsx" /* webpackChunkName: "component---src-templates-coleccion-tsx" */),
  "component---src-templates-contacto-tsx": () => import("./../../../src/templates/contacto.tsx" /* webpackChunkName: "component---src-templates-contacto-tsx" */),
  "component---src-templates-inicio-tsx": () => import("./../../../src/templates/inicio.tsx" /* webpackChunkName: "component---src-templates-inicio-tsx" */),
  "component---src-templates-nosotros-tsx": () => import("./../../../src/templates/nosotros.tsx" /* webpackChunkName: "component---src-templates-nosotros-tsx" */),
  "component---src-templates-solido-tsx": () => import("./../../../src/templates/solido.tsx" /* webpackChunkName: "component---src-templates-solido-tsx" */)
}

